/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-20 14:48:16
 * @Module: 阿里支付
 */
 <template>
  <div class="aliplay-box">
    <div v-html="h">
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      h: ''
    };
  },
  mounted () {
    const { query: params } = this.$router.history.current
    this._get_aliPlay(params)
  },
  methods: {

  },
};
 </script>
 <style lang='scss' scoped>
</style>